
import { Options, Vue } from "vue-class-component";
import CustomBtn from "@/components/CustomBtn.vue";

@Options({
  components: {
    CustomBtn,
  },
  data() {
    return {
      title: "应用下载",
      des: `您可以通过手机自带的应用商城下载“归农”App商城。华为、苹果、小米、OPPO、VIVO等手机均可下载。`,
      btns: [
        {
          title: "苹果用户下载",
          icon: require("@/assets/mallApp/app_ios_normal.png"),
          selectedIcon: require("@/assets/mallApp/app_ios_selected.png"),
        },
        {
          title: "安卓用户下载",
          icon: require("@/assets/mallApp/app_android_normal.png"),
          selectedIcon: require("@/assets/mallApp/app_android_selected.png"),
        },
      ],
    };
  },
  methods: {
    onDownload(index: number) {
      if (index === 0) {
        location.href =
          "https://apps.apple.com/cn/app/%E5%BD%92%E5%86%9C/id1419951215";
      } else {
        location.href =
          "http://qr.hotlnk.cn/guinong";
      }
    },
  },
})
export default class MallApp extends Vue {}
